<script setup lang="ts">
import type { Message } from '@/types/model';
import { nextTick, onMounted, ref, watch, type Ref } from 'vue';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { PhPlay, PhPause, PhSealQuestion, PhSpinner } from '@phosphor-icons/vue';
const hoveredMessageIndex = ref<number | null>(null);
const hoveredWordIndex = ref<number | null>(null);
import Separator from './Separator.vue';
import { apiRes, PracticeAPI, type CorrectMessageResponse } from './api';
import { domainPort, protocol } from '@/url';
const messageContainer: Ref<HTMLElement | null> = ref(null);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const correctMessageResult = apiRes<CorrectMessageResponse>();
const correctionResults: Ref<{ [messageId: string]: CorrectMessageResponse }> = ref({});
const correctingMessageId = ref<string | null>(null);

const props = defineProps<{
  pageStrings: { [key: string]: string },
  messages: Message[],
  sessionMessageIndexStart: number,
  userId: string,
  language: string,
  streamingId: string | null,
  replayingAudio: boolean,
  onPlayAudio: (messageId: string) => void,
  onStopAudio: (messageId: string) => void,
}>();

onMounted(() => {
  scrollToBottom();
});
const onHoverMessage = (messageIndex: number) => {
  hoveredMessageIndex.value = messageIndex;
};
const onHoverWord = (messageIndex: number, wordIndex: number) => {
  // hoveredMessageIndex.value = messageIndex;
  hoveredWordIndex.value = wordIndex;
};

const onLeaveWord = () => {
  // hoveredMessageIndex.value = null;
  hoveredWordIndex.value = null;
};

const scrollToBottom = () => {
  nextTick(() => {
    if (messageContainer.value) {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
    }
  });
};

const correctMessage = async (messageId: string) => {
  const message = props.messages.find(m => m.messageId === messageId);
  if (!message) return;
  correctingMessageId.value = messageId;

  // Get previous messages up to this one
  const messageIndex = props.messages.findIndex(m => m.messageId === messageId);
  const prevMessages: string[] = props.messages
    .slice(Math.max(0, messageIndex - 5), messageIndex)
    .map(m => m.content.map(c => c.content).join(''));

  const currentMessage: string = message.content.map(c => c.content).join('');

  await API.correctMessage(prevMessages, currentMessage, props.language, correctMessageResult);
  correctingMessageId.value = null;
  if (correctMessageResult.data.value) {
    correctionResults.value[messageId] = correctMessageResult.data.value;

  }

};
// Watch for changes in the messages array
watch(props.messages, () => {
  scrollToBottom();
}, { deep: true });


const getMessageContent = (message: Message) => {
  return message.content.map(c => c.content).join('');
};

</script>

<style>
.tooltip-shadow {
    box-shadow: 0px 2px 4px 0px var(--Components-shadow, rgba(0, 0, 0, 0.09));
}


.scroll-container {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.scroll-container::-webkit-scrollbar { 
  display: none; /* Chrome, Safari, Edge */
}
</style>

<template>  
<div class="w-[90%] w-3/4 px-4 pt-4 mx-auto mb-4 md:mb-8 max-w-[40rem] overflow-y-auto mt-auto scroll-container" ref="messageContainer">
  <div v-for="(message, index) in messages" :key="index" class="flex flex-col">
    <Separator class="mt-2 mb-6 text-gray-400" :label="pageStrings.thisSession" v-if="index === sessionMessageIndexStart && index > 0"/>
    <Separator class="mt-2 mb-6 text-gray-400" :label="getMessageContent(message)" v-if="message.isKickstartMessage"/>
    <div :class="[
      'p-3 rounded-lg',
      message.fromId === userId ? 'bg-bg-chat-sent text-text-chat-sent mb-4 self-end' : 'bg-bg-chat-received text-text-base shadow-chat-message w-auto max-w-[33rem] mb-4 self-start',
      message.confirmed && streamingId === message.messageId && replayingAudio? 'shadow-focus border-0' : ''
    ]"
    @mouseover="onHoverMessage(index)"
    v-if="!message.isKickstartMessage"
    >
      <p :class="[
        'text-lg m-0'
      ]">
        <span v-for="(section, idx) in message.content" :key="idx">
          <span v-if="hoveredMessageIndex === index">
            <TooltipProvider v-if="section.definition && section.definition.length > 0" :delayDuration=500 :skipDelayDuration=500>
              <Tooltip :open="hoveredMessageIndex === index && hoveredWordIndex === idx" >
                <TooltipTrigger as-child>
                  <span class="hover:rounded-xs hover:bg-bg-chat-highlight-over" 
                  @mouseover="onHoverWord(index, idx)" @mouseleave="onLeaveWord" v-html="section.content"></span>
                </TooltipTrigger>
                <TooltipContent class="text-lg bg-toolbar-background tooltip-shadow text-fg-on-inverted" :hide-when-detached="true" >
                  <p><span class="font-bold" v-html="section.content"></span>: {{section.definition}}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <span v-else v-html="section.content"></span>
          </span>
          <span v-else v-html="section.content"></span>
        </span>
      </p>
      <p v-if="message.fromId !== userId" class="text-md-medium text-text-chat-label mt-1">
        {{ message.fromName }}
        <span class="float-right">
          <PhPlay v-if="index >= sessionMessageIndexStart && message.confirmed && streamingId !== message.messageId" 
            class="w-4 h-4 ml-2 inline cursor-pointer" @click="onPlayAudio(message.messageId)" />
          <PhPause v-if="message.confirmed && streamingId === message.messageId" 
            @click="onStopAudio(message.messageId)"
            class="w-4 h-4 ml-2 inline cursor-pointer" />
        </span>
      </p>
      <p v-else  class="text-md text-text-chat-label mt-1"> 
        <span class="float-right">
          <PhSealQuestion v-if="message.fromId === userId && !(message.messageId in correctionResults) && correctingMessageId !== message.messageId"
          class="w-4 h-4 ml-2 inline cursor-pointer"
           @click="() => correctMessage(message.messageId)" />
           <PhSpinner v-if="correctingMessageId === message.messageId" class="w-4 h-4 ml-2 inline animate-spin" />
           <span v-if="message.messageId in correctionResults">
            <span v-html="correctionResults[message.messageId].correctionSummary"></span> <span v-if="correctionResults[message.messageId].score > 0">{{ correctionResults[message.messageId].score }}/10</span>
           </span>
        </span>
      </p>
    </div>
  </div>
</div>
</template>