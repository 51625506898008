<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from "@/components/ui/input";
import IssenHeader from "@/practice/IssenHeader.vue";
import { Label } from "@/components/ui/label"
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type CheckAllowlistResponse, type CheckUsernameResponse, type CreateStripePortalSessionResponse, type CreateUserResponse, type GetUserResponse, type LogoutResponse, PracticeAPI, type SubscribeResponse, type UpdateUserResponse } from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { UserStatus, type UserSettings } from "@/types/model";
  import { useRoute } from 'vue-router';
import { sha256 } from "@/practice/crypto";
import { Checkbox } from '@/components/ui/checkbox'
import { aggregationFns } from "@tanstack/vue-table";
import { priceId } from "@/practice/subscription";
const serverErrorStrings = ref<{ [key: number]: string }>({});
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogCancel, AlertDialogFooter, AlertDialogTitle, AlertDialogDescription, AlertDialogAction } from "@/components/ui/alert-dialog";
import { getErrorMessagesFromLanguage } from "@/languages/errors/serverErrors";
const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings= ref(getMessagesFromLanguage(sourceLanguage.value));
const password1 = ref("");
const password2 = ref("");
const name = ref(""); 
const createStripePortalSessionResult = apiRes<CreateStripePortalSessionResponse>();
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const logoutResult = apiRes<LogoutResponse>();
const updateUserResult = apiRes<UpdateUserResponse>();
const getUserResult = apiRes<GetUserResponse>();
const error: Ref<string> = ref("");
const route = useRoute();
const email = ref("");
const manageSubscriptionError: Ref<number | null> = ref(null);

onMounted(async () => {
    await API.getUser(getUserResult);
    if (getUserResult.data.value && getUserResult.data.value.user.sourceLang) {
      sourceLanguage.value = getUserResult.data.value.user.sourceLang;
      pageStrings.value = getMessagesFromLanguage(sourceLanguage.value);
      serverErrorStrings.value = getErrorMessagesFromLanguage(sourceLanguage.value);
    }

    email.value = route.query.email as string || "";
    if (!getUserResult.data.value || getUserResult.data.value.user.userStatus === "TRIAL") {
      if (getUserResult.data.value) {
        await API.logout(logoutResult);
      }
      router.push("/email?redirect=manage-account");
      return;
    }

    if (!getUserResult.data.value.user.name.startsWith('temp_') && !getUserResult.data.value.user.name.includes('@')) {
      name.value = getUserResult.data.value.user.name;
    }
    email.value = getUserResult.data.value.user.username;
    if (name.value === email.value) {
      name.value = "";
    }
  }
)


async function goBack() {
  router.push("/settings");
}

async function submitChanges() {

  let newUserSettings: Partial<UserSettings> = {};

  // The user is changing their name
  if (name.value === "") {
    error.value = pageStrings.value.enterName;
    return;
  }
  
  if (name.value !== "" && getUserResult.data.value && name.value !== getUserResult.data.value.user.name) {
    newUserSettings["name"] = name.value;
  }

  // The user is changing their password
  if (password1.value !== "" || password2.value !== "") {
    if (password1.value !== password2.value) {
      console.log("passwords do not match");
      error.value = pageStrings.value.passwordsDoNotMatch;
      return;
    }
    if (password1.value === "password" || password1.value === "") {
      error.value = pageStrings.value.invalidPassword;
      return;
    }
    if (email.value === "" && !route.query.email) {
      error.value = pageStrings.value.enterEmail;
      return;
    }
    const hashedPass = await sha256(password1.value);
    //@ts-ignore
    newUserSettings["password"] = hashedPass;
  }

  if (email.value !== "" && email.value !== getUserResult.data.value?.user.username) {
    newUserSettings["username"] = email.value;
  }

  if (Object.keys(newUserSettings).length > 0) {
    await API.update_user(updateUserResult, newUserSettings);
    if (updateUserResult.data.value) {
      router.push("/settings");
    } else {
      error.value = pageStrings.value.failedToUpdateUser;
    }
  }
}

async function manageSubscription() {
  await API.createStripePortalSession(createStripePortalSessionResult);
  if (createStripePortalSessionResult.data.value) {
    window.location.href = createStripePortalSessionResult.data.value.url;
  } else {
    manageSubscriptionError.value = createStripePortalSessionResult.error.value;
  }
}
</script>

<template>
  <AlertDialog :open="manageSubscriptionError !== null">
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ pageStrings.error }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ manageSubscriptionError === 35 ? pageStrings.manageSubscriptionApple : serverErrorStrings[manageSubscriptionError as number]}}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel @click="manageSubscriptionError = null">{{ pageStrings.close }}</AlertDialogCancel>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
  <span class="page-container flex flex-col">
    <div class="w-full flex justify-between">
      <IssenHeader :pageStrings="pageStrings" :getUserResult="getUserResult" :preLogout="() => {}" :postLogout="() => {}"/>
    </div>
    <div class="flex-1 flex flex-col w-full">
      <div class="flex flex-grow justify-center items-center">
        <Card class="w-[350px] bg-bg-base shadow-card-rest">
          <CardHeader class="mb-0">
            <CardTitle class="text-xl mb-0">
              {{ pageStrings.manageAccount }}</CardTitle>
          </CardHeader>
          <CardContent>
            <div v-if="error" class="text-red-500 font-bold mb-2 mt-0">{{ error }}</div>
            <div class="grid items-center w-full gap-4">
              <p class="text-md md:text-lg flex items-centee underline text-blue-500 cursor-pointer" 
                @click="manageSubscription" v-if="getUserResult.data.value && getUserResult.data.value.user.userStatus === UserStatus.SUBSCRIBED">
                {{ pageStrings.manageSubscription }}
              </p>
              <p class="text-md md:text-lg flex items-centee underline text-blue-500 cursor-pointer" 
                @click="router.push('/delete-account')" v-if="getUserResult.data.value">
                {{ pageStrings.deleteAccount }}
              </p>
              <Label class="text-md">{{ pageStrings.email }} / {{ pageStrings.username }}</Label>
              <div class="flex flex-col space-y-1.5">
                <Input type="text" v-model="email" :placeholder="email" ref="emailInput"
                  autofocus
                  @keyup.enter="submitChanges" />
              </div>
              <div class="flex flex-col space-y-1.5">
                <Label class="text-md">{{ pageStrings.name }}</Label>
                <Input type="text" v-model="name" :placeholder="pageStrings.name" />
              </div>
              <Label class="text-md">{{ pageStrings.password }}</Label>
              <div class="flex flex-col space-y-1.5">
                <Input type="password" v-model="password1" :placeholder="pageStrings.newPassword" />
              </div>
              <div class="flex flex-col space-y-1.5">
                <Input type="password" v-model="password2" :placeholder="pageStrings.confirmPassword" />
              </div>
            </div>
          </CardContent>
          <CardFooter class="flex px-6 pb-6">
            <Button @click="submitChanges" class="buttonDefault">
              {{ pageStrings.save }}
            </Button>
            <Button variant="secondary" @click="goBack" class="ml-2">
              {{ pageStrings.back }}
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  </span>
</template>
