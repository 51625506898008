import { createWebHistory, createRouter } from 'vue-router'
import ChatView from '@/views/ChatView.vue'
import EmailView from '@/views/EmailView.vue'
import PasswordView from '@/views/PasswordView.vue'
import ManageAccountView from '@/views/ManageAccountView.vue'
import NoAccess from '@/views/NoAccess.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import LoginView from '@/views/LoginView.vue'
import SubscribeView from '@/views/SubscribeView.vue'
import SubscribedView from '@/views/SubscribedView.vue'
import PendingSubscriptionView from '@/views/PendingSubscriptionView.vue'
import CancelledView from '@/views/CancelledView.vue'
import SettingsView from '@/views/SettingsView.vue'
import MaxUsage from '@/views/MaxUsage.vue'
import HistoryView from '@/views/HistoryView.vue'
import DeleteAccountView from '@/views/DeleteAccountView.vue'
import AccountDeletedView from '@/views/AccountDeletedView.vue'

const routes = [
  { path: '/', component: ChatView },
  { path: '/chat', component: ChatView },
  { path: '/email', component: EmailView },
  { path: '/password', component: PasswordView },
  { path: '/manage-account', component: ManageAccountView },
  { path: '/no-access', component: NoAccess },
  { path: '/onboarding', component: OnboardingView },
  { path: '/login', component: LoginView },
  { path: '/subscribe', component: SubscribeView },
  { path: '/subscribed', component: SubscribedView },
  { path: '/pending-subscription', component: PendingSubscriptionView },
  { path: '/cancelled', component: CancelledView },
  { path: '/settings', component: SettingsView },
  { path: '/max-usage', component: MaxUsage },
  { path: '/history', component: HistoryView },
  { path: '/delete-account', component: DeleteAccountView },
  { path: '/account-deleted', component: AccountDeletedView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  }
})

export default router;