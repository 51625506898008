<script setup lang="ts">
import router from "@/router";

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import IssenHeader from "@/practice/IssenHeader.vue";
import { ref, type Ref } from "vue";
import { getMessagesFromLanguage } from "@/languages/messages";
import { getDefaultSourceLanguage } from "@/practice/util";
import { onMounted } from 'vue';
import { apiRes, type GetUserResponse, PracticeAPI} from "@/practice/api";
import { protocol, domainPort } from "@/url";
import { PhCircleNotch } from "@phosphor-icons/vue";
import { useRoute } from 'vue-router';
import { UserStatus } from "@/types/model";

const route = useRoute();
const sourceLanguage = ref(getDefaultSourceLanguage());
const pageStrings = ref(getMessagesFromLanguage(sourceLanguage.value));
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const getUserResult = apiRes<GetUserResponse>();
const userId = ref<string | null>(null);
const loading = ref(true);
const error = ref(false);

onMounted(async () => {
  const startTime = Date.now();
  const timeoutDuration = 60 * 1000; // 1 minute in milliseconds

  while (Date.now() - startTime < timeoutDuration) {
    
    await API.getUser(getUserResult);

    if (getUserResult.data.value?.user.userStatus === UserStatus.SUBSCRIBED) {
      router.push("/subscribed");
      return;
    }
    if (getUserResult.error) {
      loading.value = false;
      error.value = true;
      return;
    }
    
    // Wait 1 second before next check
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  
  // If we get here, the timeout was reached
  loading.value = false;
  error.value = true;
})
</script>

<template>
  <span class="page-container flex flex-col min-h-screen">
    <div class="flex-1 flex flex-col items-center justify-center w-full overflow-auto relative z-10">
      <div v-if="loading" class="flex flex-col items-center gap-4">
        <PhCircleNotch class="animate-spin [animation-duration:2s] w-12 h-12" />
        <p class="text-center text-lg">{{ pageStrings.subscriptionPendingMessage }} </p>
      </div>
      <Card v-else-if="error" class="w-[380px]">
        <CardHeader>
          <CardTitle>{{ pageStrings.subscriptionTimeoutError }}</CardTitle>
        </CardHeader>
        <CardContent>
          {{ pageStrings.contactSupport }}
        </CardContent>
      </Card>
    </div>
  </span>
</template>

<style scoped>
.settings-bg {
  background: var(--bg-chat-base);
  min-height: 100vh;
  width: 100%;
}
</style>
