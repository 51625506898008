import {number, string} from "zod";

export const NUM_SKILLS = 4;

export const SkillNames: {[key:string]:string} = {
    "1": "Reading",
    "2": "Listening",
    "3": "Writing",
    "4": "Speaking",
}

export const GenderNames: {[key:string]:string} = {
    "1": "Male",
    "2": "Female",
    "3": "Other/non binary",
}

export const LanguageNames: {[key:string]:string} = {
    "1": "ENGLISH",
    "2": "SPANISH",
    "3": "JAPANESE",
    "4": "ITALIAN",
    "5": "MANDARIN_CHINESE",
    "6": "FRENCH",
    "7": "GERMAN",
    "8": "ARABIC",
    "9": "HINDI",
    "10": "PORTUGUESE",
    "11": "THAI",
    "12": "MULTILINGUAL",
}/*  */

export enum UserStatus {
    TRIAL = "TRIAL",
    STANDARD = "STANDARD",
    SUBSCRIBED = "SUBSCRIBED"
}

export enum ProficiencyLevel {
    BEGINNER = "beginner",
    INTERMEDIATE = "intermediate",
    ADVANCED = "advanced",
}

export enum TutorLanguageUsage {
    TARGET_LANGUAGE = "target_language",
    MIXED_LANGUAGE = "mixed_language",
}

export enum TutorFormalness {
    STANDARD = "standard",
    FORMAL = "formal",
    SLANG = "slang",
}

export enum StudentGrammarHelp {
    TUTOR_INITIATED = "tutor_initiated",
    STUDENT_INITIATED = "student_initiated",
}

export type ContentSection = {
    content: string,
    isWord: boolean,
    definition: string
}


export type Agent = {
    agentId: string;
    agentTemplateId: string;
    userId: string;
    voiceId: string;
    voiceService: string;
    agentType: string;
    name: string;
    language: string;
    bio: string;
    gender: string;
    age: number;
    homeCity: string;
    homeCountry: string;
    timezone?: string;
    createdDate: number;
}

export type UserSettings = {
    userId: string;
    username: string;
    name: string;
    sourceLang: string;
    hasOnboarded: boolean;
    userStatus: UserStatus;
    maxUsageStandard: number;
    tutorPatience: number;
    dailyMinutesGoal: number;
    speechSpeedRatio: number;
    selectedAgentTemplateId: string;
    llmMode: boolean;
    noiseCancellation: boolean;
    timezone?: string;
    stripeCustomerId?: string;
}

export type LanguageSettings = {
    id: string;
    userId: string;
    language: string;
    proficiencyLevel: string;
    formalness: string;
    grammarCorrection: string;
    tutorLanguageUsage: string;
}

export type KickstartMessage = {
    type: string;
    kickstart_id: string;
    kickstart_type: string;
}

export class Message {
    messageId: string;
    fromName: string;
    fromId: string;
    toId: string;
    content: ContentSection[];
    time: number;
    confirmed: boolean;
    isKickstartMessage: boolean;
    constructor(messageId: string, fromId: string, fromName: string, toId: string, content: ContentSection[], time: number, confirmed: boolean, isKickstartMessage: boolean) {
        this.messageId = messageId;
        this.fromId = fromId;
        this.fromName = fromName;
        this.toId = toId;
        this.content = content;
        this.time = time;
        this.confirmed = confirmed;
        this.isKickstartMessage = isKickstartMessage;
    }
}
