<script setup lang="ts">
import { PhChats, PhBookOpenText, PhPersonSimpleTaiChi, PhLightbulb, PhFilmSlate, PhCircleNotch  } from '@phosphor-icons/vue';
import { watch, computed, onMounted, ref, type Ref } from 'vue';
import { Button } from '@/components/ui/button';
import type { GetLocalizedKickstartsResponse, Kickstart, LocalizedKickstart, GetAvailableAgentTemplatesResponse, UpdateUserResponse } from './api';
import { PracticeAPI, apiRes } from './api';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import Separator from './Separator.vue';
import type { UserSettings } from '@/types/model';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import {domainPort, protocol} from "@/url";
import type {AgentTemplate, Voice} from "@/types/agents";
import { useRouter } from 'vue-router'
import { getLanguageFlag, getLanguageFlagCountry } from '@/practice/util';

const props = defineProps<{
  pageStrings: { [key: string]: string },
  languageProficiency: string,
  startEnabled: boolean,
  clickedStart: (kickStartMessage?: LocalizedKickstart) => void,
  localizedKickstarts: GetLocalizedKickstartsResponse | null,
  user: UserSettings | null,
  isUserLoggedIn: boolean,
  targetLanguage?: string,
  restartSession: () => void
}>();
const router = useRouter();
const selectedTutor: Ref<string | undefined> = ref(undefined);
const getAvailableAgentTemplatesResult = apiRes<GetAvailableAgentTemplatesResponse>();
const templatesForLanguage: Ref<AgentTemplate[]> = ref([]);
const API: PracticeAPI = new PracticeAPI(protocol + domainPort);
const updateUserResult = apiRes<UpdateUserResponse>();

onMounted(async () => {
  await API.getAvailableAgentTemplates(getAvailableAgentTemplatesResult);
  if (getAvailableAgentTemplatesResult.data.value?.agentTemplates) {
    console.log(getAvailableAgentTemplatesResult.data.value);
    for (const agentTemplate of getAvailableAgentTemplatesResult.data.value.agentTemplates) {
      if (agentTemplate.agentTemplateId === props.user?.selectedAgentTemplateId) {
        selectedTutor.value = agentTemplate.agentTemplateId;
      }
    }
  }
});

const startWithKickstart = (kickstart?: LocalizedKickstart) => {
  if (kickstart) {
    props.clickedStart(kickstart);
  } else {
    props.clickedStart({
      kickstart: {
        id: "open_ended",
        type: "open_ended",
        prompt: "",
        description: ""
      },
      language: "",
      localized_description: ""
    });
  }
}
const computedTemplatesForLanguage = computed(() => {
  if (props.targetLanguage === null || props.targetLanguage === undefined || props.targetLanguage === "") {
    return getAvailableAgentTemplatesResult.data.value?.agentTemplates;
  }
  return getAvailableAgentTemplatesResult.data.value?.agentTemplates.filter((agentTemplate: AgentTemplate) => agentTemplate.language === props.targetLanguage);
});
const isOnboarded = computed(() => {
  return props.user && props.user.hasOnboarded;
});

watch(selectedTutor, async (newVal, oldVal) => {
  if (!oldVal) return; // Skip first mount
  if (newVal === "change_language") {
    router.push("/settings");
  } else if (newVal !== undefined && newVal !== null) {
    await API.update_user(updateUserResult, {
      selectedAgentTemplateId: newVal
    });
    props.restartSession();
  }
});
</script>

<style scoped>  
.bg-button-primary {
  background: var(--button-primary);
}
.bg-button-secondary {
  background: var(--button-secondary);
}


</style>

<template>  
    <span class="md:p-1 mb-8 md:px-4 flex flex-col items-center gap-4 shadow-elevation-toolbar rounded-lg w-full">
        <div v-if="!isOnboarded" :class="{'invisible': !(props.user && props.isUserLoggedIn && props.startEnabled)}" class="text-hmd md:text-hlg">
           {{ pageStrings.welcomeBack }} {{ props.user?.name}}
        </div>
        <div v-else class="flex flex-row items-center gap-2 mb-2">
          <span class="text-md">{{ pageStrings.talkTo }}</span>
          <Select v-model="selectedTutor" class="ml-2" v-if="selectedTutor !==''"> 
            <SelectTrigger class="w-[150px]">
              <SelectValue :placeholder="pageStrings.selectTutor" />
            </SelectTrigger>
                <SelectContent class="bg-bg-base">
                  <SelectItem v-for="agent in computedTemplatesForLanguage"
                   :key="agent.agentTemplateId" :value="agent.agentTemplateId" class="h-9 cursor-pointer bg-bg-base hover:bg-bg-base-hover">
                      <span class="flex flex-row items-center">
                        <img :src="`/assets/country_icons/${getLanguageFlagCountry(agent.language, agent.homeCountry)}.svg`" 
                        class="w-[1.5rem] h-[1.5rem]" 
                        alt="flag" />
                        <span class="ml-2 text-md">{{ agent.name }}</span>
                      </span>
                  </SelectItem>
                  <SelectItem value="change_language" class="h-9 text-md cursor-pointer bg-bg-base hover:bg-bg-base-hover">
                    {{ pageStrings.changeLanguage }}
                  </SelectItem>
                </SelectContent>
              </Select>
        </div>
        <div class="bg-toolbar-background rounded-sm cursor-pointer">
            <Button class="text-toolbar-text-primary" :disabled="!startEnabled" @click="clickedStart(undefined)">
                <PhCircleNotch v-if="!startEnabled" class="w-6 h-6 mr-1 animate-spin"/>
                <PhChats v-else class="w-6 h-6 mr-1"/>
                {{ isOnboarded ? pageStrings.openEnded : pageStrings.startLesson}}
            </Button>
        </div>

        <Separator :maxWidth="20" :label="pageStrings.or" v-if="isOnboarded"/>

        <span  v-if="isOnboarded" class="flex flex-row flex-wrap justify-center gap-3">
            <DropdownMenu v-if="localizedKickstarts && localizedKickstarts.grammarKickstarts.length > 0">
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="" :disabled="!startEnabled">
                    <PhBookOpenText class="w-6 h-6 mr-1"/>
                    {{ pageStrings.grammarLesson}}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-60 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                  <DropdownMenuLabel class="font-normal" >
                    <span class="font-bold">{{ languageProficiency === "advanced" ? pageStrings.advancedGrammar : (languageProficiency === "intermediate" ? pageStrings.intermediateGrammar : pageStrings.beginnerGrammar)}}</span>
                  </DropdownMenuLabel>
                      <DropdownMenuSeparator />
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.grammarKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhPersonSimpleTaiChi class="w-6 h-6 mr-1"/>
                    {{ pageStrings.studentLifeKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-48 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.studentLifeKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhLightbulb class="w-6 h-6 mr-1"/>
                    {{ pageStrings.topicKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-54 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.topicKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <DropdownMenu>
              <DropdownMenuTrigger as-child>
                <Button variant="secondary" class="bg-bg-chat-received" :disabled="!startEnabled">
                    <PhFilmSlate class="w-6 h-6 mr-1"/>
                    {{ pageStrings.scenarioKickstart }}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent class="w-56 bg-bg-chat-received shadow-card-rest">
                <DropdownMenuGroup>
                    <DropdownMenuItem v-if="localizedKickstarts" 
                  v-for="kickstart in localizedKickstarts.scenarioKickstarts" 
                  :key="kickstart.kickstart.id" @click="startWithKickstart(kickstart)"
                  class="cursor-pointer">
                          {{ kickstart.localized_description }}
                    </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
        </span>
    </span>
</template>